import { useRef, useState, useEffect} from "react";
import { useMainContext } from "../contexts/MainContext";
import { windowLayouts } from "../constants";
import "../css/Window.css";
import ToolOptions from "./ToolOptions";
import Layers from "./Layers";
import Viewport from "./Viewport";
import Timeline from "./Timeline";
import Palette from "./Palette";

function TopLeftWindow() {
  const {
    topLeftWindow,
    setTopLeftWindow,
    isDocumentLoaded,
    setSavePaletteModal,
    loadPalettes,
    setSelectedViewport,
    deleteSwatch,
    resolution,
    setViewportCenter,
  } = useMainContext();

  const [scale, setScale] = useState(0.9);
  const [translate, setTranslate] = useState({ x: -50, y: -50 });

  const handleLayoutChange = (e) => {
    setTopLeftWindow(e.target.value);
    if(e.target.value === "viewport") setSelectedViewport(0);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => prevScale * 0.8);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale * 1.25);
  };

  const handleCenter = () => {
    setScale(0.9);
    setTranslate({ x: -50, y: -50 });
  };

  useEffect(() => {  
    const oX = ((translate.x + 50) / 100) * resolution.width;
    const oY = ((translate.y + 50) / 100) * resolution.height;
    const centerX = resolution.width / 2 - oX;
    const centerY = resolution.height / 2 - oY;
    setViewportCenter({x: centerX, y: centerY});
  }, [scale, resolution, translate]);

  const renderWindowContent = () => {
    switch (topLeftWindow) {
      case "toolOptions":
        return <ToolOptions />;
      case "layers":
        return <Layers />;
      case "viewport":
        return <Viewport scale={scale} setScale={setScale} translate={translate} setTranslate={setTranslate} layout={0} />;
      case "timeline":
        return <Timeline />;
      case "palette":
        return <Palette />;
      default:
        return <ToolOptions />;
    }
  };

  return (
    <div className="top-window">
      <div className="window-header">
        <select
          className="window-select"
          value={topLeftWindow}
          onChange={handleLayoutChange}
        >
          {windowLayouts.map((layout) => (
            <option key={layout.id} value={layout.id}>
              {layout.name}
            </option>
          ))}
        </select>
        <div className="grow"></div>
        {topLeftWindow === "viewport" && (
          <>
            <button 
              className="viewport-button"
              onClick={handleZoomOut}
            >
              <span className="icon-zoom-out"></span>
            </button>
            <button 
              className="viewport-button"
              onClick={handleZoomIn}
            >
              <span className="icon-zoom-in"></span>
            </button>
            <button
              className="viewport-button" 
              onClick={handleCenter}
            >
              <span className="icon-enlarge"></span>
            </button>
          </>
        )}
        {topLeftWindow === "palette" && (
          <>
            <button 
              className="palette-button" 
              onClick={() => {deleteSwatch()}}
            >
              <span className="icon-swap"></span>
            </button>
            <button 
              className="palette-button" 
              onClick={() => {loadPalettes(false)}}
            >
              <span className="icon-import"></span>
            </button>
            <button 
              className="palette-button" 
              onClick={() => {setSavePaletteModal(true)}}
            >
              <span className="icon-export"></span>
            </button>
          </>
        )}
      </div>
      {isDocumentLoaded && <div className="window-content">{renderWindowContent()}</div>}
    </div>
  );
}

export default TopLeftWindow;
