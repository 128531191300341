import { useState, useEffect } from "react";
import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";
import { onlyIntInput} from "../utils/basics";

function WandTool() {
  const { wandContinguous, setWandContinguous, wandTolerance, setWandTolerance} = useMainContext();

  const [displayTolerance, setDisplayTolerance] = useState(wandTolerance);

  const handleWandContinguous = (checked) => {
    setWandContinguous(checked);
  };

  const handleToleranceChange = (e) => {
    if(e.target.value > 255){
      setWandTolerance(255);
      setDisplayTolerance(255);
    } else if(e.target.value < 0){
      setWandTolerance(0);
      setDisplayTolerance('');
    } else {
      setWandTolerance(parseInt(e.target.value));
      setDisplayTolerance(e.target.value);
    }
  };

  return (
    <>
      <h4>Wand Tool</h4>
      <label className="tool-label">
        Continguous:
        <input
          className="tool-checkbox"
          type="checkbox"
          onChange={(e) => handleWandContinguous(e.target.checked)}
          checked={wandContinguous}
        ></input>
      </label>

      <label className="tool-label">Tolerance: <input
        className="tool-integer-big"
        type="number"
        value={displayTolerance}
        onChange={handleToleranceChange}
        onKeyDown={onlyIntInput}
        max={255}
      ></input></label>
    </>
  );
}

export default WandTool;
