import { useState, useEffect} from "react";
import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";
import { maxCursorSize } from "../constants";
import { onlyIntInput} from "../utils/basics";

function LineTool() {
  const { lineWidth, setLineWidth, lineCap, setLineCap } = useMainContext();

  const [displayWidth, setDisplayWidth] = useState(lineWidth);

  useEffect(() =>{
    setDisplayWidth(lineWidth);
  }, [lineWidth]);

  const handleWidthChange = (e) => {
    if(e.target.value > maxCursorSize){
      setLineWidth(maxCursorSize);
      setDisplayWidth(maxCursorSize);
    } else if(e.target.value <= 0){
      setLineWidth(1);
      setDisplayWidth('');
    } else {
      setLineWidth(parseInt(e.target.value));
      setDisplayWidth(e.target.value);
    }
  };

  const handleRageChange = (e) => {
    setLineWidth(parseInt(e.target.value));
  };

  const handleLineCap = (checked) => {
    setLineCap(checked);
  };

  return (
    <>
      <h4>Line Tool</h4>
      <label className="tool-label">Width: <input
        className="tool-integer"
        type="number"
        value={displayWidth}
        onChange={handleWidthChange}
        onKeyDown={onlyIntInput}
        max={maxCursorSize}
      ></input> px</label>
      <input
        className="tool-range"
        type="range"
        onChange={handleRageChange}
        value={lineWidth}
        min="1"
        max={maxCursorSize}
      ></input>
      <label className="tool-label">
        Round Cap:
        <input
          className="tool-checkbox"
          type="checkbox"
          onChange={(e) => handleLineCap(e.target.checked)}
          checked={lineCap}
        ></input>
      </label>
    </>
  );
}

export default LineTool;
