import { useState, useEffect } from "react";
import "../css/Guide.css";
import logo from '../assets/images/logo.svg';
import Hotkeys from './Hotkeys';
import { metaKey} from "../constants";

function Guide() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const handleWrapperClick = (e) => {
    if(e.target.className === "guide-sidebar-wrapper") setMenuOpen(false);
  };

  const sidebarWrapperClass = () => {
    return menuOpen ? "guide-sidebar-wrapper" : "guide-sidebar-wrapper hidden";
  };

  useEffect(() => {
    document.title = 'Aliasing.Pro - Guide';
  }, []);  

  return (
    <div className="guide-wrapper grow">
      {!menuOpen && ( <button className="menu-button" onClick={handleMenuOpen}><span className="icon-paragraph-left2"></span></button> )}
      <div className={sidebarWrapperClass()} onClick={(event) => {handleWrapperClick(event)}}>
        <div className="guide-sidebar">
          <button className="guide-sidebar-button menu" onClick={handleMenuOpen}><span className="icon-paragraph-left2"></span></button>

          <a href="/" className="guide-sidebar-heading-wrapper">
            <img src={logo} alt="Aliasing.Pro Logo" className="logo"></img>
            <h1 className="guide-sidebar-heading">Aliasing.Pro</h1>
          </a>

          <a href="#intro" className="guide-section">INTRODUCTION</a>
            <a href="#features" className="guide-item">Features</a>
            <a href="#account" className="guide-item">Account</a>
            <a href="#limitations" className="guide-item">Limitations</a>
          <a href="#interface" className="guide-section">INTERFACE</a>
            <a href="#dashboard" className="guide-item">Dashboard</a>
            <a href="#windows" className="guide-item">Windows</a>
            <a href="#dropdowns" className="guide-item">Drop Downs</a>
          <a href="#layers" className="guide-section">LAYERS</a>
          <a href="#selection" className="guide-section">SELECTION</a>
          <a href="#color" className="guide-section">COLOR</a>
          <a href="#tools" className="guide-section">TOOLS</a>
            <a href="#hand" className="guide-item">Hand</a>
            <a href="#zoom" className="guide-item">Zoom</a>
            <a href="#eyedropper" className="guide-item">Eyedropper</a>
            <a href="#move" className="guide-item">Move</a>
            <a href="#pencil" className="guide-item">Pencil</a>
            <a href="#eraser" className="guide-item">Eraser</a>
            <a href="#line" className="guide-item">Line</a>
            <a href="#bucket" className="guide-item">Bucket</a>
            <a href="#gradient" className="guide-item">Gradient</a>
            <a href="#rectangle" className="guide-item">Rectangle</a>
            <a href="#ellipse" className="guide-item">Ellipse</a>
            <a href="#wand" className="guide-item">Wand</a>
            <a href="#crop" className="guide-item">Crop</a>
            <a href="#transform" className="guide-item">Transform</a>
          <a href="#guides" className="guide-section">GUIDES</a>
          <a href="#hotkeys" className="guide-section">HOTKEYS</a>
        </div>
      </div>

      <div className="guide">
        <h1 className="guide-title">Aliasing.Pro Guide</h1>

          <div className="video-wrapper">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/9MNOm2DLqNQ?si=beEZCp7YALs6ovc2" title="YouTube video player" frameborder="0" allowfullscreen="allowfullscreen" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>
          </div>

          <h2 className="guide-heading"><a name="intro">INTRODUCTION</a></h2>
          <p>
            Aliasng.Pro is a web application for creating pixel art with limited 32 color palettes.
            You can create art on desktops, phones, and tablets, all from within your web browser.
          </p>
          <h3 className="guide-heading indent"><a name="features">Features</a></h3>
          <p className="indent">
            Aliasing.pro lets you draw with up to 32 canvas layers to be exported as PNG, GIF, or JPEG with up to  32 colors plus transparency for PNG or GIF.
            You can upscale your work to show off your art with crisp edges.
            It's pencil and eraser tools work with styluses and pressure sensitivity to allow you to control the diameter or dither.
            Use the selection tools to constrain your drawings as well as utilize copying and pasting.
            Advanced guide tools let you snap the tools using the <span className="underline">shift</span> key.
            The document has a history that you can undo and redo with up to the last 32 changes.
            You can scale, translate, and rotate a layer or selection to modifiy your drawing.
            <br></br><br></br>
            You can open existing images which makes a palette from sampled colors, replace the colors with another palette, or combine colors from the sampled palette and those from another palette.
            Auto save is turned on by default which means your document will save every two minutes.
          </p>
          <a name="intro"></a><h3 className="guide-heading indent"><a name="account">Account</a></h3>
          <p className="indent">
            You can sign up for a free account to access your documents and palettes across devices.
            Alternatively, you can create documents without signing in and still access them localy.
          </p>
          <h3 className="guide-heading indent"><a name="limitations">Limitations</a></h3>
          <p className="indent">
            Aliasing.Pro has certain limitations to achieve a retro feel. The max canvas size is 1024 by 1024 pixels and individual layers max out at 2048 by 2048 pixels.
            Most pixel art will be significantly lower resolution.
            Performance is better on smaller canvas sizes and devices with newer hardware.
            The app works on desktops, tablets, and phones but the interface is a bit cramp on most phones.
            It is best to work on a desktop or tablet with a pressure sensitive stylus and keyboard for hotkeys.
            The color palette is limited to 32 colors plus alpha.
            The web app does not support partial transparency, only fully opaque colors or full transparency.
            When you change the color of a swatch in your palette it will update that color everywhere in the document.
          </p>



          <h2 className="guide-heading"><a name="interface">INTERFACE</a></h2>
          <p className="indent">
            Aliasing.Pro features a responsive and intuitive interface. 
            The tool bar on the left lets you select between the tools, choose slected and secondary colors, apply <span className="underline">Shift</span>, <span className="underline">Alt</span>, and <span className="underline">{metaKey}</span> to your tools, as well as undo and redo.
          </p>
          <h3 className="guide-heading indent"><a name="dashboard">Dashboard</a></h3>
          <p className="indent">
            The <a href="/dashboard">dashboard</a> is where you can create new documents and browse your existing documents.
            Documents can be starred to easily find your most important documents using the star sort in the sidebar.
            Documents can be organized into groups by dragging and dropping onto another document or group.
            You can archive documents and groups that you are not currently working on.
          </p>
          <h3 className="guide-heading indent"><a name="windows">Windows</a></h3>
          <p className="indent">
            The editor can show up to 4 windows at a time. The windows can be resized by dragging the dividers.
            The viewport window is where you can draw and modify your art.
            On mobile devices you can use two fingers to pinch zoom or drag the canvas. <span className="underline">Space</span> and scroll wheel can be used to to pan and zoom respectively on desktop.
            The tool options window lets you change the setting of your current tool.
            The layers window lets you select and organize your layers.
            The palette window is where you can edit the swatches in your palette and save and load palettes if you are signed in.
          </p>
          <h3 className="guide-heading indent"><a name="dropdowns">Drop Downs</a></h3>
          <p className="indent">
            The header at the top of the editor has drop downs for accessing a variety of settings and buttons.
            The <span className="underline">File</span> drop down is where you can save and change the settings of a document or create a new document.
            The <span className="underline">Edit</span> drop down includes options for editing your canvas settings such as resizing, flipping and rotating the canvas.
            The <span className="underline">Layer</span> drop down is for adding, deleting, duplicating, and merging layers.
            The <span className="underline">Select</span> drop down has controls for the current selection.
            The <span className="underline">Guides</span> drop down is for adding guide layesr.
            Lastly, <span className="underline">About</span> links to this page, the video tutorial, the Discord, a support page, and the hotkeys modal.
          </p>





          <h2 className="guide-heading"><a name="layers">LAYERS</a></h2>
          <p>
            Aliasing.Pro allows up to 32 canvas layers at a time and any number of guide layers.
            You can select multiple layers  by holding the <span className="underline">Ctrl</span> or <span className="underline">Shift</span> key when clicking.
            You can reorder layers in the layer window by dragging them.
            You can rename a layer by clicking on the pencil button to the right of the layer.
            You can change the visibility of layers with the <span className="icon-eye"></span> button. Hidden layers cannot be modified.
            <br></br><br></br>
            You can add canvas layers with the <span className="icon-plus"></span> button, using the <span className="underline">N</span> hotkey, or <span className="underline">Layers &gt; Add Canvas</span>.
            You can delete layers with the <span className="icon-bin"></span> button, using the <span className="underline">Delete</span> hotkey, or <span className="underline">Layers &gt; Delete Layers</span>.
            You can merge layers using the with the <span className="icon-stack2"></span> button or <span className="underline">Layers &gt; Merge Down</span>.
            When merging and one layer is selected it will merge with the next canvas layer beneath the selected layer.
            If multiple canvas layers are selected it will merge those layers.
            You can duplicate layers with <span className="underline">{metaKey}+J</span> or <span className="underline">Layers &gt; Duplicate Layer</span>.
            You can right click on the view port to see a context menu with the layers that have pixels under that point.
            <br></br><br></br>
            There is a thumbnail on the left of each layer.
            If you hold <span className="underline">Ctrl</span> and click the thumbnail it will select the opaque pixels of that layer.
            By default this replaces the selection. However, if you also hold <span className="underline">Shift</span> you can add to the layer's pixels to the current selection.
            Holding <span className="underline">Alt</span> removes the layer's pixels from the current selection. <span className="underline">Alt</span>, 
            and <span className="underline">Shift</span> together selects the overlapping are between the current selection and the layer's pixels.
            <br></br><br></br>
            You can open a context menu for selecting from the layers under the cursor by right clicking.
          </p>





          <h2 className="guide-heading"><a name="selection">SELECTION</a></h2>
          <p>
            When you have a selection on the canvas your tools are restricted to modifying the selected pixels.
            Selections are all or nothing since Aliasing.Pro does not have partial alpha.
            The rectangle, ellipse, and wand tools can be used to add to the selection for drawing, transforming, or copy/pasting.
            <br></br><br></br>
            By default these tools replace the selection. However, if you hold <span className="underline">Shift</span> you can add to the current 
            selection. <span className="underline">Alt</span> removes from the current
            selection. <span className="underline">Alt</span> and <span className="underline">Shift</span> together selects the overlapping are between the current selection and the new 
            area. <span className="underline">Ctrl</span> clicking on a layer thumbnail or palette swatch selects the opaque pixels from that layer or color and works the same with <span className="underline">Shift</span> and <span className="underline">Alt</span>.
            <br></br><br></br>
            Use <span className="underline">{metaKey}+A</span> or <span className="underline">Selection &gt; Select All</span> to select the entire canvas.
            Use <span className="underline">{metaKey}+D</span> or <span className="underline">Selection &gt; Deselect</span> to deselect all selected pixels.
            You can also use <span className="underline">{metaKey}+I</span> or <span className="underline">Selection &gt; Invert</span> to invert the selection.
          </p>



          <h2 className="guide-heading"><a name="color">COLOR</a></h2>
          <p>
            Each document has 32 color swatches plus a swatch for alpha.
            When creating a document you will be able to choose a starting palette, either choosing from a default palette, one you have saved, or one imported from an image, .txt file, or .hex file.
            When creating a document from an existing image you can make a palette from a combination of the sampled colors and another palette.
            <br></br><br></br>
            When editing a document, the Palette window is used to manage your colors.
            The web app has a selected color swatch and a secondary color swatch at any given time.
            Use the <span className="underline">D</span> key to select the first two colors in the palette.
            You can swap between the selected and secondary colors using the <span className="underline">X</span> key or the button under the swatch icons in the toolbar.
            Clicking a swatch in the palette sets that as the selected color.
            Clicking the selected color swatch in the palette window opens a modal for modifying the selected color.
            When you update a swatch in the palette, it updates that color on all layers in the document.
            You can reorder swatches by dragging them.
            <br></br><br></br>
            You can delete a color and any pixels of that color in the document will be replaced with the closest color in your paltte.
            If you are signed in you can save palettes for later with the <span className="icon-export"></span> button.
            You can replace your palette with another using the <span className="icon-import"></span> button.
            You can then choose what colors to keep and which to add from the new palette.
            When doing so you can load a palette from an image, .txt file, or .hex file.
            <br></br><br></br>
            The eyedropper tool can be used to select a color from the canvas.
            The replace color tool option of the eyedropper makes the current selected color duplicate the color you 
            click. <span className="underline">Edit &gt; Mix Colors</span> causes the selected color to be replaced by a new color that is half way between the selected color and secondary color.
            <br></br><br></br>
            You can make selections from a color swatch by holding <span className="underline">Ctrl</span> and clicking the swatch.
            By default this replaces the selection. However, if you also hold <span className="underline">Shift</span> it adds to the selection.
            Holding <span className="underline">Alt</span> removes from the selection. <span className="underline">Shift</span> and <span className="underline">Alt</span> selects the overlap between the selection and the selected color.
          </p>



          <h2 className="guide-heading"><a name="tools">TOOLS</a></h2>
          <p>
            There are a number of tools to create pixel art using Aliasing.Pro accessible from the toolbar on the left or hotkeys.
            The toolbar also has buttons for the current selected color swatches and buttons for undo and redo.
          </p>
          <h3 className="guide-heading indent"><a name="hand">Hand</a></h3>
          <p className="indent">
            The hand tool is used for translating your view of the canvas in the viewport.
            You can select the hand tool with the <span className="icon-hand-paper-o"></span> button or the <span className="underline">H</span> hotkey.
            You can also translate the view of the canvas by holding <span className="underline">Space</span> and dragging.
          </p>
          <h3 className="guide-heading indent"><a name="zoom">Zoom</a></h3>
          <p className="indent">
            The zoom toll is used for zooming in and out of the canvas in the viewport.
            You can select the zoom tool with the <span className="icon-zoom-in"></span> button or the <span className="underline">Z</span> hotkey.
            You can hold <span className="underline">Alt</span> to zoom out.
            If you have the invert zoom option checked in the tool options window  it will instead zoom out by default and in if you hold <span className="underline">Alt</span>.
            You can also zoom with the scroll wheel or by pinching with multiple fingers on touch devices.
          </p>
          <h3 className="guide-heading indent"><a name="eyedropper">Eyedropper</a></h3>
          <p className="indent">
            The eyedropper tool is for choosing a color from within the canvas.
            You can select the eyedropper tool with the <span className="icon-eyedropper"></span> button or the <span className="underline">I</span> hotkey.
            By default it sets the current selected swatch to the color of the pixel on the top layer where you click or tap.
            If you have the current layer checkbox checked in the tool options window it will instead select the color of the pixel on the currently selected layer.
            The replace color checkbox lets you replace the current selected color with a copy of the clicked pixel in the canas.
          </p>
          <h3 className="guide-heading indent"><a name="move">Move</a></h3>
          <p className="indent">
            The move tool is used to translate the selected layers.
            You can select the move tool with the <span className="icon-move"></span> button or the <span className="underline">M</span> hotkey.
            If the auto-select checkbox in the tool options window is checked the tool will select the top layer under the cursor when starting a move.
            When the layers bounds are moved past the bounds of the canvas it makes the layers bigger to extend the layers to cover the canvas bounds.
            Each layer has a max resolution of 2048 by 2048 pixels.
            When moving the layers there is a green dotted guide that shows how far the layers can be dragged before reaching max resolution.
          </p>
          <h3 className="guide-heading indent"><a name="pencil">Pencil</a></h3>
          <p className="indent">
            The pencil tool is used for drawing on the canvas with the selected color.
            You can select the pencil tool with the <span className="icon-pencil6"></span> button or the <span className="underline">P</span> hotkey.
            You can change the diameter of the pencil using the slider in the tool options window,
            by using the hotkeys &#91; and &#93; while the pencil tool is selected,
            or by holding <span className="underline">{metaKey}</span> and clicking and dragging.
            If the diameter is set to one pixel you can enable pixel perfect mode to draw without extra corners between the drawn pixels.
            <br></br><br></br>
            There is a dither mode that lets you combine the selected color and secondary color based on the pressure or dither ratio setting.
            The dither offset is shared with the other dither tools.
            <br></br><br></br>
            The selection mode adds to the selection where you draw.
            <br></br><br></br>
            Pressure sensitivity is turned on by default which sets the diameter or dither ratio based on how hard you press with a stylus.
            The sensitity slider in the tool options window modifies the amount of pressure needed on a logarithmic scale.
            The higher the sensitivity, the harder you have to press to achieve the same diameter.
            <br></br><br></br>
            You can draw lines between your clicks by holding <span className="underline">Alt</span>.
          </p>
          <h3 className="guide-heading indent"><a name="eraser">Eraser</a></h3>
          <p className="indent">
            The eraser tool works similarly to the pencil tool except it sets the drawn pixels to alpha.
            You can select the eraser tool with the <span className="icon-eraser"></span> button or the <span className="underline">E</span> hotkey.
            You can change the diameter of the eraser using the slider in the tool options window, by using the hotkeys &#91; and &#93; while the eraser tool is selected, or by holding <span className="underline">{metaKey}</span> and clicking and dragging.
            If the diameter is set to one pixel you can enable pixel perfect mode to erase without extra corners between the erased pixels.
            <br></br><br></br>
            There is a dither mode that lets you erase with a dither pattern based on the dither ratio setting or pressure sensitivity.
            The dither offset is shared with the other dither tools.
            <br></br><br></br>
            The selection mode removes from the selection where you draw.
            <br></br><br></br>
            Pressure sensitivity is turned on by default which sets the diameter or dither ratio based on how hard you press with a stylus.
            The sensitity slider in the tool options window modifies the amount of pressure needed on a logarithmic scale.
            The higher the sensitivity, the harder you have to press to achieve the same diameter.
            <br></br><br></br>
            You can erase lines between your clicks by holding <span className="underline">Alt</span>.
          </p>
          <h3 className="guide-heading indent"><a name="line">Line</a></h3>
          <p className="indent">
            The line tool draws a straight line between two points.
            You can change the width of the line using the slider in the tool options window or by using the hotkeys &#91; and &#93; while the line tool is selected.
            You can choose between having a flat or round cap in the tool options window.
          </p>
          <h3 className="guide-heading indent"><a name="bucket">Bucket</a></h3>
          <p className="indent">
            The bucket tool is used to fill a section of the current layer with the current selected color.
            You can select the bucket tool with the <span className="icon-bucket"></span> button or the <span className="underline">B</span> hotkey.
            <br></br><br></br>
            By default, contiguous is turned on which means it will only affect the pixels of the same color that are orthoganaly adjacent.
            If contiguous is turned off in the tool options window it will fill all pixels with that color in the whole layer within the bounds of the canvas.
            <br></br><br></br>
            There is a tolerance setting that can be set to values between 0 and 255.
            With tolerance 0 the bucket only fills the exact color clicked.
            Higher values fill a larger range of colors.
            <br></br><br></br>
            The bucket has a dither mode that lets you combine the selected color and secondary color based on the dither ratio setting.
            The dither offset is shared with the other dither tools.
          </p>
          <h3 className="guide-heading indent"><a name="gradient">Gradient</a></h3>
          <p className="indent">
            The gradient tool is used to fill a section of the current layer with a dither that transitions between the selected color and secondary color.
            You can select the gradient tool with the <span className="icon-gradient"></span> button or the <span className="underline">G</span> hotkey.
            The dither offset is shared with the other dither tools.
          </p>
          <h3 className="guide-heading indent"><a name="rectangle">Rectangle</a></h3>
          <p className="indent">
            The rectangle tool lets you create a selection that is a rectangle.
            You can select the rectangle tool with the <span className="icon-dash-square"></span> button or the <span className="underline">R</span> hotkey.
            By default, this replace the selection. However, if you hold <span className="underline">Shift</span> when starting your selection adds the rectangle to the current
            selection. <span className="underline">Alt</span> when starting the selection removes the rectangle from the current
            selection. <span className="underline">Alt</span> and <span className="underline">Shift</span> together when starting the selection sets the selection to the overlapping are between the rectangle and the current selection.
            <br></br><br></br>
            Holding <span className="underline">Shift</span> while dragging constrains the rectangle to a square.
            Holding <span className="underline">Alt</span> while dragging centers the rectangle around the starting point.
            <br></br><br></br>
            The rectangle tool has an angle setting for setting the angle of the selection. Positive angles are counter clockwise.
          </p>
          <h3 className="guide-heading indent"><a name="ellipse">Ellipse</a></h3>
          <p className="indent">
            The ellipse tool lets you create a selection that is a ellipse.
            You can select the ellipse tool with the <span className="icon-dash-circle"></span> button or the <span className="underline">Shift&#8209;E</span> hotkey.
            By default, this replace the selection. However, if you hold <span className="underline">Shift</span> when starting the selection you can add the ellipse to the current
            selection. <span className="underline">Alt</span> when starting the selection removes the ellipse from the current
            selection. <span className="underline">Alt</span> and <span className="underline">Shift</span> together when starting the selection sets the selection to the overlapping are between the ellipse and the current selection.
            <br></br><br></br>
            Holding <span className="underline">Shift</span> while dragging constrains the ellipse to a circle.
            Holding <span className="underline">Alt</span> while dragging centers the ellipse around the starting point.
            <br></br><br></br>
            The Ellipse tool has an angle setting for setting the angle of the selection. Positive angles are counter clockwise.
          </p>
          <h3 className="guide-heading indent"><a name="wand">Wand</a></h3>
          <p className="indent">
            The wand tool is select the parts of the current layer with the color or apha of the clicked or tapped pixel.
            You can select the wand tool with the <span className="icon-magic-wand"></span> button or the <span className="underline">W</span> hotkey.
            <br></br><br></br>
            By default, contiguous is turned on which means it will only affect the pixels of the same color that are orthoganaly adjacent.
            If contiguous is turned off in the tool options window it will fill all pixels with that color in the whole layer within the bounds of the canvas.
            Wand opperations may be slow on large canvases.
            <br></br><br></br>
            There is a tolerance setting that can be set to values between 0 and 255.
            With tolerance 0 the wand only selects the exact color clicked.
            Higher values select a larger range of colors.
          </p>
          <h3 className="guide-heading indent"><a name="crop">Crop</a></h3>
          <p className="indent">
            The crop tool is for changing the resolution and offset of the canvas.
            You can select the crop tool with the <span className="icon-enlarge"></span> button or the <span className="underline">C</span> hotkey.
            To reset the crop, use <span className="underline">Esc</span> or the reset button in the tool options window.
            To apply the crop use <span className="underline">Enter</span> or the apply button in the tool options window.
            <br></br><br></br>
            Dragging the center of the crop offsets the canvas.
            You can drag the corners and edges of the crop to scale it.
            <br></br><br></br>
            When dragging a corner, you can hold <span className="underline">Shift</span> to maintain the aspect ratio of the crop.
            Alternatively you can check the maintain ratio checkbox in the tool options window to achieve the same effect.
            Holding <span className="underline">Alt</span> or checking the center box in tool options makes the selection scale from both sides around the center of the current crop.
            <br></br><br></br>
            The max canvas size is 1024 by 1024 pixels.
            The resolution of all layers will be increased to make them fit within the new canvas bounds.
            The crop won't let you move the canvas bounds past what would set the layers to 2048 by 2048 pixels.
            <br></br><br></br>
            You can also crop the canvas around the center by using <span className="underline">Edit &gt; Crop Canvas</span>.
            You can scale the canvas using <span className="underline">Edit &gt; Scale Canvas</span>.
          </p>
          <h3 className="guide-heading indent"><a name="transform">Transform</a></h3>
          <p className="indent">
            The transform tool is for translating, scaling, and rotating a selection within a layer.
            Use the <span className="icon-transform"></span> button to start a transformation, the hotkey <span className="underline">T</span>, or use <span className="underline">Edit &gt; Transform</span>.
            To reset a transformation use <span className="underline">Esc</span> or the reset button in the tool options window.
            To apply the transformation use <span className="underline">Enter</span> or the apply button in the tool options window.
            You can also apply, reset, rotate, or flip the transformation with the right click context menu.
            There is a crosshair that when moved changes the pivot point when rotating and scaling.
            <br></br><br></br>
            Dragging the inside of the transformation translates the selection.
            You can drag the corners and edges of the transform selection to scale the selection.
            If you hold <span className="underline">{metaKey}</span> while dragging a corner or edge it will skew the transformation and allows for perspective transformations.
            Concave transformations do not function.
            <br></br><br></br>
            To rotate the selection, drag from outside the bounds of the selection.
            Holding <span className="underline">shift</span> when rotating snaps the rotation to the closest increment of 11.25 degrees.
            <br></br><br></br>
            When scaling, you can hold <span className="underline">Shift</span> to maintain the aspect ratio of the selection.
            Holding <span className="underline">Alt</span> makes the selection scale from both sides around the transform center you have selcted.
            <br></br><br></br>
            The transformation won't draw past the max layer size of 2048 by 2048 pixels.
          </p>
          
          <h2 className="guide-heading"><a name="guides">GUIDES</a></h2>
          <p>
            You can add guide layers from the <span className="underline">Guides</span> drop down.
            You can also show or hide the viewport boundaries from the same drop down.
            When a guide layer is selected the guide tool becomes selected which allows you to modify guide layers in the viewport or from the tool options window.
            You can also translate guide layers with the move tool.
            The color of a guide can also be changed to keep your guides organized.
            Once you change a guide color the next guide you create will default to that color.
            Positive angles are measured counter clockwise.
            <br></br><br></br>
            One guide layer at a time can have magnet mode turned on in the layers window which lets other tools and guides snap to them if the <span className="underline">shift</span> key is held.
            If no guide layer is in magnet mode, <span className="underline">shift</span> snaps to the vertical or horizontal.
            <br></br><br></br>
            Line layers let you specify a start point and an end point for a line segment or a line that extends past the viewport at that angle. 
            When the line layer is snapped to, the angle is either parallel to or perpendicular to the angle of the line.
            <br></br><br></br>
            Vanishing point layers are points that, when snapped to, make a straight line from the starting point to the vanishing point. 
            They default to the center of the viewport.
            <br></br><br></br>
            Perspective layers start as two point perspective guides with a 45 degree angle, a focal length measured in pixels, and a horizon line at the center of the viewport. 
            The default focal length is the equivalent of a 35 mm lens on a full frame camera.
            Once you change the focal length for a layer, the next perspetive guide you create will default to that focal length.
            When snapping it chooses the vanishing point with the closest angle to your input and also includes the horizontal and vertical lines.
            If set angle 2 to a value other than zero a vertical view line will be displayed with a third vanishing point for three point perspective.
            The tilt setting lets you achieve a dutch tilt effect.
            <br></br><br></br>
            Grid layers are grids with a corner point, width, height, and angle.
            Grid layers default to the viewport size with 3 rows and columns.
            Rectangle guides can be created by setting both the rows and columns to 1.
            When the Grid layer is snapped to, the angle is either parallel to or perpendicular to the angle of the grid.
            <br></br><br></br>
            Isometric layers are similar to grid layers but the angle of the edges can be changed irrespective of eachother.
            When snapping it snaps to the closest of the two angles or the vertical.
            <br></br><br></br>
            Ellipse layers let you specify a center, width, height, and angle to define an ellipse.
            When editing the width with the guide tool it also sets the angle and you can snap with <span className="underline">shift</span> like other guides and tools.
            When editing the hieght, on the other hand, <span className="underline">shift</span> makes the ellipse circular.
            When the snapping to an ellipse layer with the pencil or eraser tool it will draw that ellipse with the radius that you start with.
            Other tools snap to the angle of the ellipse or the perpendicular one.
          </p>

          <a name="hotkeys"></a>
          <div className="hotkeys-wrapper">
            <Hotkeys />
          </div>
      </div>
    </div>
  );
}

export default Guide;
