import { useEffect, useState, useRef } from "react";
import "../css/Export.css";
import { useMainContext } from "../contexts/MainContext";
import { onlyIntInput } from "../utils/basics";

function Export({ onSave }) {
    const {
        docName,
        filename,
        setFilename,
        exportType,
        setExportType,
        setUpscaling,
        setExportNow,
        maxUpscale,
        palette,
        bgColor,
        setBgColor,
    } = useMainContext();
    
    const [errorMessage, setErrorMessage] = useState(null);
    const [tempScaling, setTempScaling] = useState(1);

    const nameRef = useRef(null);

    useEffect(() => {
        if (nameRef.current) {
            nameRef.current.focus();
        }
        setExportType("png");
        setUpscaling(1);
    }, []);

    const handleNameChange = (e) => {
        setFilename(e.target.value);
    };

    const handleTypeChange = (e) => {
        setExportType(e.target.value);
    };

    const handleScalingChange = (e) => {
        console.log(maxUpscale)
        setTempScaling(e.target.value);
        if(e.target.value !== ''){
            let newValue = e.target.value;
            if(newValue < 1) newValue = 1;
            if(newValue > maxUpscale) newValue = maxUpscale;
            setUpscaling(newValue);
            setTempScaling(newValue);
        }
    };

    const handleSubmit = async (e) => {
        if(filename.length < 1) return setErrorMessage("Filenam is required.");
        if(!/^[A-Za-z0-9 _-]+$/.test(filename)) return setErrorMessage("Filenam contains invalid characters.");

        setExportNow((prevValue) => prevValue + 1);

        onSave();
    };

    const generateOptions = () => {
      const options = [];
      for (let i = 1; i <= maxUpscale; i++) {
        options.push(
          <option key={i} value={i}>
            {i * 100}%
          </option>
        );
      }
      return options;
    };

    return (
        <div className="export-wrapper">
            <h2 className="export-heading">Export</h2>
            <label className="export-label" htmlFor="name">Filename:</label>
            <input ref={nameRef} id="name" className="export-input-name" value={filename} onChange={handleNameChange} autocomplete="off"/>
            <label className="export-label" htmlFor="type">File Type:</label>
            <select className="export-select" name="type" id="type" onChange={handleTypeChange}>
                <option value="png">PNG</option>
                <option value="gif">GIF</option>
                <option value="jpg">JPG</option>
            </select>

            {exportType === "jpg" && 
                <div className="background-swatches">
                    {palette && palette.map((swatch, index) => {
                        if(swatch) return (
                            <div
                                key={index}
                                className="background-swatch"
                                style={{ 
                                    backgroundColor: swatch,
                                    borderColor: bgColor === index ? "limegreen" : swatch,
                                }}
                                onClick={() => setBgColor(index)}
                            ></div>
                        );
                    })}
                </div>
            }

            <label className="export-label">Scaling: <input
              className="export-integer"
              type="number"
              onChange={handleScalingChange}
              value={tempScaling}
              min={1} 
              max={maxUpscale}
              onKeyDown={onlyIntInput}
            ></input> Max: {maxUpscale}</label>

            {errorMessage && <div className="export-error">{errorMessage}</div>}
            <div className="export-bottom"><input type="submit" value="Export" onClick={(event) => {handleSubmit(event)}}/></div>
        </div>
    );
}

export default Export;
