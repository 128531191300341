import { useState, useEffect } from "react";
import { maxResolution, maxLayerSize} from "../constants";
import { useMainContext } from "../contexts/MainContext";
import "../css/ScaleCanvas.css";
import { onlyIntInput} from "../utils/basics";

function ScaleCanvas({ onScale}) {
    const {
        layers,
        resolution,
        offset,
        version,
        rescale
    } = useMainContext();

    const [width, setWidth] = useState(resolution.width);
    const [height, setHeight] = useState(resolution.height);
    const [aspectRatio, setAspectRatio] = useState(resolution.width/resolution.height);
    const [displayWidth, setDisplayWidth] = useState(width);
    const [displayHeight, setDisplayHeight] = useState(height);
    const [maxWidth, setMaxWidth] = useState(maxResolution);
    const [maxHeight, setMaxHeight] = useState(maxResolution);
    const [maintainRatio, setMaintainRatio] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        let maxScaleX = maxResolution/resolution.width;
        let maxScaleY = maxResolution/resolution.height;
        for(const layer of layers){
            if(layer.type !== "Canvas") continue;
            let maxLayerWidth = maxLayerSize/layer.ref.current.width;
            let maxLayerHeight = maxLayerSize/layer.ref.current.height;
            maxScaleX = Math.min(maxScaleX, maxLayerWidth);
            maxScaleY = Math.min(maxScaleY, maxLayerHeight);
        }
        
        const tempMaxWidth = Math.round(resolution.width * maxScaleX);
        const tempMaxWeight = Math.round(resolution.height * maxScaleY);
        setMaxWidth(tempMaxWidth);
        setMaxHeight(tempMaxWeight)
    }, [layers, resolution, offset, aspectRatio, version]);

    const handleWidthChange = (e) => {
        if(e.target.value > maxWidth){ 
            if(maintainRatio){
                if(e.target.value / aspectRatio > maxHeight){
                    setHeight(maxHeight);
                    setDisplayHeight(maxHeight);
                    setWidth(Math.round(maxHeight * aspectRatio));
                    setDisplayWidth(Math.round(maxHeight * aspectRatio));
                } else {
                    setWidth(maxWidth);
                    setDisplayWidth(maxWidth);
                    setHeight(Math.round(maxWidth / aspectRatio));
                    setDisplayHeight(Math.round(maxWidth / aspectRatio));
                }
            } else {
                setWidth(maxWidth);
                setDisplayWidth(maxWidth);
            }
        } else if(e.target.value <= 0){
            setWidth(1);
            setDisplayWidth(''); 
            if(maintainRatio){
                setHeight(1);
                setDisplayHeight('');
            }
        } else {
            if(maintainRatio){
                if(e.target.value / aspectRatio > maxHeight){
                    setHeight(maxHeight);
                    setDisplayHeight(maxHeight);
                    setWidth(Math.round(maxHeight * aspectRatio));
                    setDisplayWidth(Math.round(maxHeight * aspectRatio));
                } else {
                    setWidth(e.target.value);
                    setDisplayWidth(e.target.value);
                    setHeight(Math.round(e.target.value / aspectRatio));
                    setDisplayHeight(Math.round(e.target.value / aspectRatio));
                }
            } else {
                setWidth(e.target.value);
                setDisplayWidth(e.target.value);
            }
        }
    };
    const handleHeightChange = (e) => {
        if(e.target.value > maxHeight){ 
            if(maintainRatio){
                if(e.target.value * aspectRatio > maxWidth){
                    setWidth(maxWidth);
                    setDisplayWidth(maxWidth);
                    setHeight(Math.round(maxWidth / aspectRatio));
                    setDisplayHeight(Math.round(maxWidth / aspectRatio));
                } else {
                    setHeight(maxHeight);
                    setDisplayHeight(maxHeight);
                    setWidth(Math.round(maxHeight * aspectRatio));
                    setDisplayWidth(Math.round(maxHeight * aspectRatio));
                }
            } else {
                setHeight(maxHeight); 
                setDisplayHeight(maxHeight);
            }
        } else if(e.target.value <= 0){
            setHeight(1);
            setDisplayHeight('');
            if(maintainRatio){
                setWidth(1);
                setDisplayHeight('');
            }
        } else {
            if(maintainRatio){
                if(e.target.value * aspectRatio > maxWidth){
                    setWidth(maxWidth);
                    setDisplayWidth(maxWidth);
                    setHeight(Math.round(maxWidth / aspectRatio));
                    setDisplayHeight(Math.round(maxWidth / aspectRatio));
                } else {
                    setHeight(e.target.value);
                    setDisplayHeight(e.target.value);
                    setWidth(Math.round(e.target.value * aspectRatio));
                    setDisplayWidth(Math.round(e.target.value * aspectRatio));
                }
            } else {
                setHeight(e.target.value);
                setDisplayHeight(e.target.value);
            }
        }
    };
    
    // Helper function to store document ID in local storage
    const handleSubmit = (e) => {
        e.preventDefault();

        if(width > maxWidth) return setErrorMessage("Width must be less than maxResolution px.");
        if(height > maxHeight) return setErrorMessage("Height must be less than maxResolution px.");
        if(!/^[0-9]+$/.test(width)) return setErrorMessage("Width contains invalid characters.");
        if(!/^[0-9]+$/.test(height)) return setErrorMessage("Height contains invalid characters.");

        rescale(parseInt(width),parseInt(height));
                
        onScale();
    };

    const handleMaintainRatio = (checked) => {
      setMaintainRatio(checked);
      setHeight(Math.round(width / aspectRatio));
      setDisplayHeight(Math.round(width / aspectRatio));
    };

    return (
        <div className="scale-wrapper">
            <h2 className="scale-heading">Scale Canvas</h2>
            <label className="scale-label" htmlFor="width">New Width:</label>
            <div className="scale-input-wrapper">
                <input
                    id="width"
                    type="number"
                    className="scale-input"
                    value={displayWidth}
                    onKeyDown={onlyIntInput}
                    onChange={handleWidthChange}
                    max={maxWidth}
                /> Max {maxWidth}px
            </div>
            <label className="scale-label" htmlFor="height">New Height:</label>
            <div className="scale-input-wrapper">
                <input
                    id="height"
                    type="number"
                    className="scale-input"
                    value={displayHeight}
                    onKeyDown={onlyIntInput}
                    onChange={handleHeightChange}
                    max={maxHeight}
                /> Max {maxHeight}px
            </div>
            <label className="ratio-label">
                Maintain Ratio:
                <input
                className="tool-checkbox"
                type="checkbox"
                onChange={(e) => handleMaintainRatio(e.target.checked)}
                checked={maintainRatio}
                ></input>
            </label>
            {errorMessage && <div className="scale-error">{errorMessage}</div>}
            <div className="scale-bottom"><input type="submit" value="Resize" onClick={(event) => {handleSubmit(event)}}/></div>
        </div>
    );
}

export default ScaleCanvas;
